<template>
  <S2SForm title="Refunds">
    <S2SCard>
      <v-form ref="form" lazy-validation>
        <v-flex xs12 md6>
          <v-autocomplete
            label="Search Customer"
            v-model="selectedOrganisationId"
            :items="searchedOrganisations"
            item-value="id"
            item-text="name"
            :search-input.sync="searchVal"
            :loading="orgLoading"
            cache-items
            data-vv-name="selectedOrganisationId"
            v-validate="'required'"
            :error-messages="errors.collect('customer')"
          ></v-autocomplete>
        </v-flex>
        <v-flex xs12 md6 v-if="searchedOrganisations.length">
          <v-text-field
            label="Shop Code"
            disabled
            v-model="searchedOrganisations[0].code"
          ></v-text-field>
        </v-flex>
        <v-flex xs12 md6>
          <v-text-field
            label="Order ID (Optional)"
            mask="########"
          ></v-text-field>
        </v-flex>
        <v-flex xs12>
          <v-flex xs12 md6>
            <v-text-field
              label="Amount"
              id="amount"
              ref="amount"
              v-money="currencyOptions"
              v-model.lazy="amount"
              data-vv-name="amount"
              v-validate="'required|currency'"
              :error-messages="errors.collect('amount')"
            ></v-text-field>
          </v-flex>
          <v-flex xs12 md6>
            <v-text-field
              label="Reference"
              v-model="reference"
              data-vv-name="reference"
              v-validate="'required'"
              :error-messages="errors.collect('reference')"
            ></v-text-field>
          </v-flex>
          <v-flex xs12 class="text-xs-right">
            <v-btn
              :block="$vuetify.breakpoint.xsOnly"
              color="accent"
              @click="onRefund()"
              :disabled="refundLoading"
              >Refund</v-btn
            >
          </v-flex>
        </v-flex>
      </v-form>
    </S2SCard>
  </S2SForm>
</template>

<script>
  import Vue from "vue";
  import VeeValidate from "vee-validate";
  import debounce from "lodash.debounce";

  const VMoney = require("v-money").VMoney;

  export default Vue.extend({
    name: "Refunds",

    directives: { money: VMoney },

    data: function () {
      return {
        // Form Data
        amount: "0.00",
        reference: "",
        searchVal: "",
        selectedOrganisationId: 0,

        /* Currency Input Settings */
        currencyOptions: {
          decimal: ".",
          thousands: " ",
          prefix: "R ",
          precision: 2,
          masked: false,
        },

        debounce: () => {},
      };
    },

    computed: {
      searchedOrganisations: function () {
        return this.$store.state.shops.searchedOrganisations;
      },
      activeShop: function () {
        return this.$store.state.shops.activeShop;
      },
      orgLoading: function () {
        return this.$store.state.shops.loading;
      },
      refundLoading: function () {
        return this.$store.state.transactions.refundLoading;
      },
    },

    watch: {
      searchVal: function () {
        if (!this.searchVal) return;
        this.debounce();
      },
    },

    methods: {
      async onRefund() {
        if (this.orgLoading) return;
        if (await this.$validator.validateAll()) {
          let success = this.$store.dispatch("transactions/doRefund", {
            transactingOrgId: +this.activeShop.id,
            txnType: "refund",
            from: {
              account: {
                amount: Number(this.amount.replace(/[A-Za-z\s]/g, "")),
              },
            },
            to: {
              organisation: {
                id: this.selectedOrganisationId,
              },
            },
            sourceReference: this.reference,
            targetReference: this.reference,
          });

          if (success) {
            const form = this.$refs.form;
            const amountField = this.$refs.amount;

            amountField.$el.getElementsByTagName("input")[0].value = 0;
            this.selectedOrganisationId = 0;

            this.$nextTick(() => {
              form.reset();
              this.$validator.reset();
            });
          }
        }
      },
      async resetFields() {
        const form = this.$refs.form;
        const amountField = this.$refs.amount;

        // v-money clear workaround
        amountField.$el.getElementsByTagName("input")[0].value = 0;
        this.selectedOrganisationId = 0;

        setTimeout(function () {
          // resets form values
          form.reset();
          form.resetValidation();
        }, 1000);
      },
      validateDictionary() {
        return {
          dictionary: {
            attributes: {
              amount: "Refund Amount",
              reference: "Reference",
              customer: "Customer",
            },
          },
        };
      },
    },

    created: function () {
      this.$validator.localize("en", this.validateDictionary().dictionary);
      this.$validator.extend("currency", {
        getMessage(field, val) {
          return "Min amount must R 0.01";
        },
        validate(value, field) {
          return Number(value.replace(/[A-Za-z\s]/g, "")) > 0;
        },
      });

      // Initial debounce func for shop search
      this.debounce = debounce(() => {
        this.$store.dispatch("shops/searchOrganisations", this.searchVal);
      }, 500);
    },
  });
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
